/*
This file is just for the body layout. To make it responsive, it works with wcmc_hero_threecol.js
Which duplicates the related-content sidebar contents into an area below the fold for smaller screens
*/

// Bring in a couple files from Bootstrap so we can use the clearfix and vendor mixins (for transition, etc)
@import "../../scss/bootstrap/mixins/_clearfix";
@import "../../scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../scss/_variables";
@import "../../scss/_mixins";

/* =WCMC Hero Three Column
----------------------------------------------------------*/

.panel-fullhero-3col {
  position: relative;
  @include clearfix();
}

// This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
.container .container {
  margin-left: -20px;
  margin-right: -20px;
}

.hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    height: 300px;
  }
}

#related-content-sidebar {
  display: none;
  @include breakpoint($sm) {
    width: 220px;
    margin-left: 40px;
    float: right;
    display: block;
  }
}

.no-js #related-content-sidebar {
  display: block;
}

#related-content-inline {
  margin-bottom: 2em;
  @include breakpoint($sm) {
    display: none;
  }
}

.pane-node-field-related-articles {
  clear: both;
}